import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MandantClient } from '@shared/models/mandantenClient';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigAssetLoaderService } from './config-asset-loader.service';

@Injectable({
    providedIn: 'root'
})
export class MandantDarstellungService {
    // Fallback url, falls kein Wappen gefunden wurde
    private wappenFallbackUrl = 'assets/img/k5buergerservice.jpg';

    // Wappen src für die Anzeige im Menü
    private mandantDarstellung: BehaviorSubject<MandantClient.UIInitialisierungMandantDarstellung> =
        new BehaviorSubject<MandantClient.UIInitialisierungMandantDarstellung>({
            uiDarstellung: MandantClient.UiDarstellung.Default,
            nameZeile1: '',
            nameZeile2: '',
            bildContentType: MandantClient.BildContentType.Jpeg,
            bildUi: '',
            bildDataUriPrefix: ''
        });

    mandantDarstellung$: Observable<MandantClient.UIInitialisierungMandantDarstellung> =
        this.mandantDarstellung.asObservable();

    // Id vom aktuellen Mandanten des Benutzers
    initialMandantId: string;

    constructor(private http: HttpClient, private configService: ConfigAssetLoaderService) {}

    /**
     * Verarbeitet die Logo Daten des Mandanten
     * @param mandantDarstellung Darstellung des Mandanten Logos
     * @returns src für das Wappen Image
     */
    buildWappenSource(mandantDarstellung: MandantClient.UIInitialisierungMandantDarstellung): string {
        let wappenSrc = this.wappenFallbackUrl;
        if (mandantDarstellung?.bildUi) {
            let dataUriPrefix = 'data:image/';
            switch (mandantDarstellung.bildContentType) {
                case MandantClient.BildContentType.Jpeg:
                    dataUriPrefix += 'jpeg';
                    break;
                case MandantClient.BildContentType.Png:
                    dataUriPrefix += 'png';
                    break;
                case MandantClient.BildContentType.Svg:
                    dataUriPrefix += 'svg+xml';
                    break;
                case MandantClient.BildContentType.None:
                    return '';
            }
            dataUriPrefix += ';base64,';
            wappenSrc = dataUriPrefix + mandantDarstellung.bildUi;
        }
        return wappenSrc;
    }

    /**
     * Updated die src des Wappens im Menü
     */
    updateMandantDarstellung(mandantDarstellung: MandantClient.UIInitialisierungMandantDarstellung): void {
        this.mandantDarstellung.next(mandantDarstellung);
    }

    /**
     * Speichert die Darstellung eines Mandanten inklusive Logo
     * @param mandantDarstellung Darstellung des Mandanten
     * @param mandantId Mandant Id
     * @returns updated Mandant
     */
    updateMandantDarstellungV2(
        mandantDarstellung: MandantClient.DarstellungData,
        mandantId: string
    ): Observable<MandantClient.MandantWithCapabilities> {
        return this.http.put<MandantClient.MandantWithCapabilities>(
            `${
                this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrlUIApi
            }/admin/Mandant/${mandantId}/Darstellung`,
            mandantDarstellung
        );
    }

    /**
     * Speichert die Darstellung eines Mandanten inklusive Logo
     * @param mandantDarstellung Darstellung des Mandanten
     * @returns
     */
    postMandantDarstellung(
        mandantDarstellung: MandantClient.SaveMandantDarstellung,
        mandantId: string
    ): Observable<MandantClient.MandantDarstellung> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'k5-mandantid': mandantId
            })
        };
        return this.http.post<MandantClient.MandantDarstellung>(
            `${this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrlUIApi}/Mandant/Darstellung`,
            mandantDarstellung,
            options
        );
    }

    /**
     * Gibt die Darstellung eines Mandanten zurück
     * @param mandantId ID des Mandanten dessen Details gelesen werden sollen
     */
    getMandantDarstellung(mandantId: string): Observable<MandantClient.MandantDarstellung> {
        return this.http.get<MandantClient.MandantDarstellung>(
            `${
                this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrlUIApi
            }/Mandant/Darstellung?mandantId=${mandantId}`
        );
    }
}
